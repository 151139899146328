* {
    font-family: Roboto, sans-serif;
}

body {
    margin: 0;
    background-color: #3E71E3;
}

.stadium {
    height:100vm; width:100vm; /* IE9 fallback */
    width: 100vmin;
    height: 100vmin;
    /*Centering */
    position: absolute;
    top:0; bottom:0;
    left:0; right:0;
    margin: auto;
}

.stadium .court {
    position: relative;
    width: 85%;
    height: 85%;
    float: right;
    background-color: #D97E68;
    box-shadow: inset 0px 0px 0px 0.5vmin white, 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.stadium .court .three-line {
    position: absolute;
    top: 33.33%;
    left: 0;
    width: 100%;
    height: 0.5vmin;
    background-color: white;
}

.stadium .court .boundaries {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 10;
    pointer-events: none;
}

.stadium .court .boundaries .boundary-vertical {
    position: absolute;
    background-color: black;
    height: 100%;
    transition: width 0.8s ease-in-out;
}

.stadium .court .boundaries .boundary-horizontal {
    position: absolute;
    background-color: black;
    width: 100%;
    transition: height 0.8s ease-in-out;
}

.stadium .court .boundary {
    position: absolute;
    height: 100%;
    width: 80%;
    right: 0;
    background-color: black;
    opacity: 0.6;
}

.stadium .court .player {
    position: absolute;
    width: 10%;
    height: 10%;
    box-sizing: border-box;
    border: 0.5vmin solid white;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    transition: inset 0.8s ease-in-out, transform 0.3s ease;
}

.stadium .court .player.selected {
    transform: scale(1.1);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.stadium .court .player.setter {
    background-color: cornflowerblue;
}

.stadium .court .player.outside-hitter {
    background-color: forestgreen;
}

.stadium .court .player.middle-blocker {
    background-color: crimson;
}

.stadium .court .player.right-side-hitter {
    background-color: teal;
}

.stadium .court .player.libero {
    background-color: goldenrod;
}

.stadium .court .player .player-label {
    width: 100%;
    background: none;
    color: white;
    padding: 0;
    border: none;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
}

.pulse {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 2vmin rgba(255, 255, 255, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.stadium .court .player-position {
    position: absolute;
    font-weight: 700;
    font-size: 20vmin;
    opacity: 0.1;
    color: black;
    user-select: none;
}

.stadium .button-panel {
    height: 15%;
    width: 85%;
    float: right;
    color: white;
    display: flex;
    justify-content: space-between;
}

.stadium .button-panel .button-group {
    flex: 1;
    padding-top: 1rem;
}

.button-basic {
    border: none;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    background-color: #D97E68;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    cursor: pointer;
    padding: 0.625rem;
}

.button-basic:active {
    transform: translateY(2px);
    box-shadow: inset 0 3px 6px rgba(0,0,0,0.16), inset 0 3px 6px rgba(0,0,0,0.23);
}

.button-rounded {
    border-radius: 50%;
    width: 2.4375rem;
    height: 2.4375rem;
}

.button-curved {
    border-radius: 1.25rem;
}

@media(max-width: 576px) {
    html {
        font-size: 12px;
    }
}